module.exports = {
	"inports": {
		"db": {
			"process": "Write",
			"port": "db"
		},
		"store": {
			"process": "Store",
			"port": "in"
		},
		"item": {
			"process": "EnsureId",
			"port": "in"
		}
	},
	"outports": {
		"key": {
			"process": "Delete",
			"port": "key"
		},
		"error": {
			"process": "Errors",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"HoldMode": {
			"component": "strings/SendString"
		},
		"Store": {
			"component": "core/Repeat"
		},
		"Write": {
			"component": "indexeddb/BeginTransaction"
		},
		"GetStore": {
			"component": "indexeddb/GetStore"
		},
		"Delete": {
			"component": "indexeddb/Delete"
		},
		"EnsureId": {
			"component": "ui/EnsureId"
		},
		"Errors": {
			"component": "core/Merge"
		}
	},
	"connections": [
		{
			"data": "readwrite",
			"tgt": {
				"process": "HoldMode",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "HoldMode",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "HoldMode",
				"port": "out"
			},
			"tgt": {
				"process": "Write",
				"port": "mode"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "Write",
				"port": "stores"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "GetStore",
				"port": "name"
			}
		},
		{
			"src": {
				"process": "Write",
				"port": "transaction"
			},
			"tgt": {
				"process": "GetStore",
				"port": "transaction"
			}
		},
		{
			"src": {
				"process": "GetStore",
				"port": "store"
			},
			"tgt": {
				"process": "Delete",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "EnsureId",
				"port": "id"
			},
			"tgt": {
				"process": "Delete",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "Write",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetStore",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Delete",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "trash"
	},
	"caseSensitive": false
};