module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"project": {
			"process": "OriginalProject",
			"port": "out"
		},
		"graph": {
			"process": "OriginalGraph",
			"port": "out"
		},
		"component": {
			"process": "OriginalComponent",
			"port": "out"
		},
		"spec": {
			"process": "OriginalSpec",
			"port": "out"
		},
		"runtime": {
			"process": "OriginalRuntime",
			"port": "out"
		},
		"error": {
			"process": "Errors",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"GetProjectsDb": {
			"component": "ui/GetActionValues"
		},
		"SaveProject": {
			"component": "ui/Save"
		},
		"ProjectsStore": {
			"component": "strings/SendString"
		},
		"OriginalProject": {
			"component": "core/SendNext"
		},
		"ConvertProject": {
			"component": "ui/CleanProject"
		},
		"Errors": {
			"component": "core/Merge"
		},
		"GetGraphsDb": {
			"component": "ui/GetActionValues"
		},
		"SaveGraph": {
			"component": "ui/Save"
		},
		"GraphsStore": {
			"component": "strings/SendString"
		},
		"OriginalGraph": {
			"component": "core/SendNext"
		},
		"ConvertGraph": {
			"component": "objects/CallMethod"
		},
		"GetComponentsDb": {
			"component": "ui/GetActionValues"
		},
		"SaveComponent": {
			"component": "ui/Save"
		},
		"ComponentsStore": {
			"component": "strings/SendString"
		},
		"OriginalComponent": {
			"component": "core/SendNext"
		},
		"GetSpecsDb": {
			"component": "ui/GetActionValues"
		},
		"SaveSpec": {
			"component": "ui/Save"
		},
		"SpecsStore": {
			"component": "strings/SendString"
		},
		"OriginalSpec": {
			"component": "core/SendNext"
		},
		"GetRuntimesDb": {
			"component": "ui/GetActionValues"
		},
		"SaveRuntime": {
			"component": "ui/Save"
		},
		"RuntimesStore": {
			"component": "strings/SendString"
		},
		"OriginalRuntime": {
			"component": "core/SendNext"
		}
	},
	"connections": [
		{
			"data": "storage:save:project,storage:save:graph,storage:save:component,storage:save:spec,storage:save:runtime",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetProjectsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SaveProject",
				"port": "db"
			}
		},
		{
			"data": "projects",
			"tgt": {
				"process": "ProjectsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "GetProjectsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "out"
			},
			"tgt": {
				"process": "OriginalProject",
				"port": "data"
			}
		},
		{
			"src": {
				"process": "SaveProject",
				"port": "item"
			},
			"tgt": {
				"process": "OriginalProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ProjectsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ProjectsStore",
				"port": "out"
			},
			"tgt": {
				"process": "SaveProject",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ConvertProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ConvertProject",
				"port": "out"
			},
			"tgt": {
				"process": "SaveProject",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "SaveProject",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetGraphsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SaveGraph",
				"port": "db"
			}
		},
		{
			"data": "graphs",
			"tgt": {
				"process": "GraphsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "GetGraphsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "out"
			},
			"tgt": {
				"process": "OriginalGraph",
				"port": "data"
			}
		},
		{
			"src": {
				"process": "SaveGraph",
				"port": "item"
			},
			"tgt": {
				"process": "OriginalGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "out"
			},
			"tgt": {
				"process": "GraphsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GraphsStore",
				"port": "out"
			},
			"tgt": {
				"process": "SaveGraph",
				"port": "store"
			}
		},
		{
			"data": "toJSON",
			"tgt": {
				"process": "ConvertGraph",
				"port": "method"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ConvertGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ConvertGraph",
				"port": "out"
			},
			"tgt": {
				"process": "SaveGraph",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "SaveGraph",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetComponentsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SaveComponent",
				"port": "db"
			}
		},
		{
			"data": "components",
			"tgt": {
				"process": "ComponentsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "GetComponentsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "out"
			},
			"tgt": {
				"process": "OriginalComponent",
				"port": "data"
			}
		},
		{
			"src": {
				"process": "SaveComponent",
				"port": "item"
			},
			"tgt": {
				"process": "OriginalComponent",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ComponentsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ComponentsStore",
				"port": "out"
			},
			"tgt": {
				"process": "SaveComponent",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "out"
			},
			"tgt": {
				"process": "SaveComponent",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "SaveComponent",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetSpecsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SaveSpec",
				"port": "db"
			}
		},
		{
			"data": "specs",
			"tgt": {
				"process": "SpecsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetSpecsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "out"
			},
			"tgt": {
				"process": "OriginalSpec",
				"port": "data"
			}
		},
		{
			"src": {
				"process": "SaveSpec",
				"port": "item"
			},
			"tgt": {
				"process": "OriginalSpec",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "out"
			},
			"tgt": {
				"process": "SpecsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SpecsStore",
				"port": "out"
			},
			"tgt": {
				"process": "SaveSpec",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "out"
			},
			"tgt": {
				"process": "SaveSpec",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "SaveSpec",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetRuntimesDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SaveRuntime",
				"port": "db"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "RuntimesStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "GetRuntimesDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "OriginalRuntime",
				"port": "data"
			}
		},
		{
			"src": {
				"process": "SaveRuntime",
				"port": "item"
			},
			"tgt": {
				"process": "OriginalRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimesStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RuntimesStore",
				"port": "out"
			},
			"tgt": {
				"process": "SaveRuntime",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "SaveRuntime",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "SaveRuntime",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		}
	],
	"caseSensitive": false
};