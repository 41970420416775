module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"pass": {
			"process": "Dispatch",
			"port": "pass"
		},
		"new": {
			"process": "NewActions",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"UserInfoAction": {
			"component": "ui/SetAction"
		},
		"NewActions": {
			"component": "core/Merge"
		},
		"UserErrorAction": {
			"component": "ui/SetAction"
		},
		"ApplicationRedirectAction": {
			"component": "ui/SetAction"
		},
		"RegistryRuntimesFetch": {
			"component": "ui/SetAction"
		},
		"UserLogoutAction": {
			"component": "ui/SetAction"
		},
		"CheckQuery": {
			"component": "ui/CheckTokenGrant"
		},
		"ExchangeToken": {
			"component": "ui/ExchangeGrantToken"
		},
		"GetRemoteUser": {
			"component": "ui/GetRemoteUser"
		},
		"StoreUser": {
			"component": "ui/StoreUser"
		},
		"LoadUser": {
			"component": "ui/LoadUserData"
		},
		"RefreshUser": {
			"component": "ui/RefreshRemoteUser"
		},
		"Login": {
			"component": "ui/LoginOAuth"
		},
		"Reset": {
			"component": "ui/ClearUserData"
		},
		"GetUserStateForUpdate": {
			"component": "ui/GetActionValues"
		},
		"StoreUserPreferences": {
			"component": "ui/StoreUserPreferences"
		}
	},
	"connections": [
		{
			"data": "application:url,user:login,user:logout,user:update",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"data": "user:info",
			"tgt": {
				"process": "UserInfoAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "UserInfoAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "user:error",
			"tgt": {
				"process": "UserErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "UserErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "application:redirect",
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationRedirectAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "flowhub:runtimes:fetch",
			"tgt": {
				"process": "RegistryRuntimesFetch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RegistryRuntimesFetch",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "user:logout",
			"tgt": {
				"process": "UserLogoutAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "UserLogoutAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "CheckQuery",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckQuery",
				"port": "code"
			},
			"tgt": {
				"process": "ExchangeToken",
				"port": "code"
			}
		},
		{
			"src": {
				"process": "CheckQuery",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ExchangeToken",
				"port": "token"
			},
			"tgt": {
				"process": "GetRemoteUser",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "ExchangeToken",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRemoteUser",
				"port": "user"
			},
			"tgt": {
				"process": "StoreUser",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetRemoteUser",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreUser",
				"port": "user"
			},
			"tgt": {
				"process": "UserInfoAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreUser",
				"port": "user"
			},
			"tgt": {
				"process": "RegistryRuntimesFetch",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreUser",
				"port": "redirect"
			},
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckQuery",
				"port": "pass"
			},
			"tgt": {
				"process": "LoadUser",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "LoadUser",
				"port": "user"
			},
			"tgt": {
				"process": "UserInfoAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadUser",
				"port": "user"
			},
			"tgt": {
				"process": "RefreshUser",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RefreshUser",
				"port": "pass"
			},
			"tgt": {
				"process": "UserInfoAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RefreshUser",
				"port": "updated"
			},
			"tgt": {
				"process": "StoreUser",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "RefreshUser",
				"port": "invalid"
			},
			"tgt": {
				"process": "UserLogoutAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadUser",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RefreshUser",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "Login",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Login",
				"port": "codeurl"
			},
			"tgt": {
				"process": "CheckQuery",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Login",
				"port": "redirect"
			},
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Login",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "Reset",
				"port": "clear"
			}
		},
		{
			"src": {
				"process": "Reset",
				"port": "user"
			},
			"tgt": {
				"process": "UserInfoAction",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "GetUserStateForUpdate",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetUserStateForUpdate",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStateForUpdate",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "StoreUserPreferences",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetUserStateForUpdate",
				"port": "out"
			},
			"tgt": {
				"process": "StoreUserPreferences",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreUserPreferences",
				"port": "out"
			},
			"tgt": {
				"process": "UserInfoAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreUserPreferences",
				"port": "error"
			},
			"tgt": {
				"process": "UserErrorAction",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "user-circle"
	},
	"caseSensitive": false
};