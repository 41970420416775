module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"pass": {
			"process": "Dispatch",
			"port": "pass"
		},
		"new": {
			"process": "NewActions",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"ApplicationRedirectAction": {
			"component": "ui/SetAction"
		},
		"NewActions": {
			"component": "core/Merge"
		},
		"OpenMainAction": {
			"component": "ui/SetAction"
		},
		"SaveGraphAction": {
			"component": "ui/SetAction"
		},
		"SaveComponentAction": {
			"component": "ui/SetAction"
		},
		"SaveSpecAction": {
			"component": "ui/SetAction"
		},
		"SaveProjectAction": {
			"component": "ui/SetAction"
		},
		"GithubPullAction": {
			"component": "ui/SetAction"
		},
		"GithubOperationAction": {
			"component": "ui/SetAction"
		},
		"GithubLoadingAction": {
			"component": "ui/SetAction"
		},
		"GithubReadyAction": {
			"component": "ui/SetAction"
		},
		"ErrorAction": {
			"component": "ui/SetAction"
		},
		"CheckRepo": {
			"component": "ui/CheckRepoExists"
		},
		"CheckGist": {
			"component": "ui/CheckGistExists"
		},
		"GetGistToken": {
			"component": "ui/GetUserToken"
		},
		"GetGist": {
			"component": "ui/GistToProject"
		},
		"GetPrepareToken": {
			"component": "ui/GetUserToken"
		},
		"GithubSynchronization": {
			"component": "ui/GithubSynchronization"
		},
		"GetSyncToken": {
			"component": "ui/GetUserToken"
		},
		"GetPullToken": {
			"component": "ui/GetUserToken"
		}
	},
	"connections": [
		{
			"data": "github:open,github:gist,github:sync:prepare,github:sync:synchronize,github:sync:pull",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"data": "application:sethash",
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationRedirectAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "main:open",
			"tgt": {
				"process": "OpenMainAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "OpenMainAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:graph",
			"tgt": {
				"process": "SaveGraphAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "SaveGraphAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:component",
			"tgt": {
				"process": "SaveComponentAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "SaveComponentAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:spec",
			"tgt": {
				"process": "SaveSpecAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "SaveSpecAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:project",
			"tgt": {
				"process": "SaveProjectAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "SaveProjectAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "github:sync:pull",
			"tgt": {
				"process": "GithubPullAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "GithubPullAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "github:operation",
			"tgt": {
				"process": "GithubOperationAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "GithubOperationAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "github:loading",
			"tgt": {
				"process": "GithubLoadingAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "GithubLoadingAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "github:ready",
			"tgt": {
				"process": "GithubReadyAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "GithubReadyAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "github:error",
			"tgt": {
				"process": "ErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "CheckRepo",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckRepo",
				"port": "existing"
			},
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckRepo",
				"port": "openmain"
			},
			"tgt": {
				"process": "OpenMainAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckRepo",
				"port": "new"
			},
			"tgt": {
				"process": "GithubPullAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "CheckGist",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckGist",
				"port": "existing"
			},
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckGist",
				"port": "new"
			},
			"tgt": {
				"process": "GetGistToken",
				"port": "in"
			}
		},
		{
			"data": "1",
			"tgt": {
				"process": "GetGistToken",
				"port": "limit"
			}
		},
		{
			"src": {
				"process": "GetGistToken",
				"port": "token"
			},
			"tgt": {
				"process": "GetGist",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetGistToken",
				"port": "out"
			},
			"tgt": {
				"process": "GithubLoadingAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGistToken",
				"port": "out"
			},
			"tgt": {
				"process": "GetGist",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGistToken",
				"port": "error"
			},
			"tgt": {
				"process": "ErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGist",
				"port": "graph"
			},
			"tgt": {
				"process": "SaveGraphAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGist",
				"port": "component"
			},
			"tgt": {
				"process": "SaveComponentAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGist",
				"port": "project"
			},
			"tgt": {
				"process": "GithubReadyAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGist",
				"port": "project"
			},
			"tgt": {
				"process": "SaveProjectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGist",
				"port": "error"
			},
			"tgt": {
				"process": "ErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "GetPrepareToken",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetPrepareToken",
				"port": "token"
			},
			"tgt": {
				"process": "GithubSynchronization",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetPrepareToken",
				"port": "out"
			},
			"tgt": {
				"process": "GithubLoadingAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetPrepareToken",
				"port": "out"
			},
			"tgt": {
				"process": "GithubSynchronization",
				"port": "prepare"
			}
		},
		{
			"src": {
				"process": "GetPrepareToken",
				"port": "error"
			},
			"tgt": {
				"process": "ErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "operation"
			},
			"tgt": {
				"process": "GithubReadyAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "operation"
			},
			"tgt": {
				"process": "GithubOperationAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "error"
			},
			"tgt": {
				"process": "ErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetSyncToken",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSyncToken",
				"port": "token"
			},
			"tgt": {
				"process": "GithubSynchronization",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetSyncToken",
				"port": "out"
			},
			"tgt": {
				"process": "GithubSynchronization",
				"port": "sync"
			}
		},
		{
			"src": {
				"process": "GetSyncToken",
				"port": "error"
			},
			"tgt": {
				"process": "ErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "graph"
			},
			"tgt": {
				"process": "SaveGraphAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "component"
			},
			"tgt": {
				"process": "SaveComponentAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "spec"
			},
			"tgt": {
				"process": "SaveSpecAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "project"
			},
			"tgt": {
				"process": "SaveProjectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "loading"
			},
			"tgt": {
				"process": "GithubLoadingAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubSynchronization",
				"port": "ready"
			},
			"tgt": {
				"process": "GithubReadyAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "GetPullToken",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetPullToken",
				"port": "token"
			},
			"tgt": {
				"process": "GithubSynchronization",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetPullToken",
				"port": "out"
			},
			"tgt": {
				"process": "GithubSynchronization",
				"port": "pull"
			}
		},
		{
			"src": {
				"process": "GetPullToken",
				"port": "error"
			},
			"tgt": {
				"process": "ErrorAction",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "github"
	},
	"caseSensitive": false
};