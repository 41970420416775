module.exports = {
	"inports": {
		"name": {
			"process": "Open",
			"port": "name"
		}
	},
	"outports": {
		"error": {
			"process": "Errors",
			"port": "out"
		},
		"db": {
			"process": "Open",
			"port": "db"
		}
	},
	"groups": [],
	"processes": {
		"Open": {
			"component": "indexeddb/Open"
		},
		"Errors": {
			"component": "core/Merge"
		},
		"Upgrade": {
			"component": "indexeddb/UpgradeRouter"
		},
		"CreateProjects": {
			"component": "indexeddb/CreateStore"
		},
		"CreateGraphs": {
			"component": "indexeddb/CreateStore"
		},
		"GraphProject": {
			"component": "indexeddb/CreateIndex"
		},
		"Migrate": {
			"component": "ui/MigrateLocalStorage"
		},
		"CreateComponents": {
			"component": "indexeddb/CreateStore"
		},
		"ComponentProject": {
			"component": "indexeddb/CreateIndex"
		},
		"CreateRuntimes": {
			"component": "indexeddb/CreateStore"
		},
		"CreateJournals": {
			"component": "indexeddb/CreateStore"
		},
		"JournalGraph": {
			"component": "indexeddb/CreateIndex"
		},
		"CreateSpecs": {
			"component": "indexeddb/CreateStore"
		},
		"SpecProject": {
			"component": "indexeddb/CreateIndex"
		},
		"DatabaseReady": {
			"component": "ui/DatabaseReady"
		}
	},
	"connections": [
		{
			"src": {
				"process": "Open",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "4",
			"tgt": {
				"process": "Open",
				"port": "version"
			}
		},
		{
			"src": {
				"process": "Open",
				"port": "upgrade"
			},
			"tgt": {
				"process": "Upgrade",
				"port": "upgrade"
			}
		},
		{
			"data": "projects",
			"tgt": {
				"process": "CreateProjects",
				"port": "name"
			}
		},
		{
			"data": "id",
			"tgt": {
				"process": "CreateProjects",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "Upgrade",
				"port": "versions"
			},
			"tgt": {
				"process": "CreateProjects",
				"port": "db"
			}
		},
		{
			"src": {
				"process": "CreateProjects",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "graphs",
			"tgt": {
				"process": "CreateGraphs",
				"port": "name"
			}
		},
		{
			"data": "id",
			"tgt": {
				"process": "CreateGraphs",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "CreateProjects",
				"port": "db"
			},
			"tgt": {
				"process": "CreateGraphs",
				"port": "db"
			}
		},
		{
			"src": {
				"process": "CreateGraphs",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GraphProject",
				"port": "name"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GraphProject",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "CreateGraphs",
				"port": "store"
			},
			"tgt": {
				"process": "GraphProject",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GraphProject",
				"port": "store"
			},
			"tgt": {
				"process": "Migrate",
				"port": "graphstore"
			}
		},
		{
			"src": {
				"process": "GraphProject",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "components",
			"tgt": {
				"process": "CreateComponents",
				"port": "name"
			}
		},
		{
			"data": "id",
			"tgt": {
				"process": "CreateComponents",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "CreateGraphs",
				"port": "db"
			},
			"tgt": {
				"process": "CreateComponents",
				"port": "db"
			}
		},
		{
			"src": {
				"process": "CreateComponents",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "ComponentProject",
				"port": "name"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "ComponentProject",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "CreateComponents",
				"port": "store"
			},
			"tgt": {
				"process": "ComponentProject",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "ComponentProject",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "CreateRuntimes",
				"port": "name"
			}
		},
		{
			"data": "id",
			"tgt": {
				"process": "CreateRuntimes",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "Upgrade",
				"port": "versions"
			},
			"tgt": {
				"process": "CreateRuntimes",
				"port": "db"
			}
		},
		{
			"src": {
				"process": "CreateRuntimes",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "journals",
			"tgt": {
				"process": "CreateJournals",
				"port": "name"
			}
		},
		{
			"data": "id",
			"tgt": {
				"process": "CreateJournals",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "Upgrade",
				"port": "versions"
			},
			"tgt": {
				"process": "CreateJournals",
				"port": "db"
			}
		},
		{
			"src": {
				"process": "CreateJournals",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "graph",
			"tgt": {
				"process": "JournalGraph",
				"port": "name"
			}
		},
		{
			"data": "graph",
			"tgt": {
				"process": "JournalGraph",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "CreateJournals",
				"port": "store"
			},
			"tgt": {
				"process": "JournalGraph",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "JournalGraph",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "specs",
			"tgt": {
				"process": "CreateSpecs",
				"port": "name"
			}
		},
		{
			"data": "id",
			"tgt": {
				"process": "CreateSpecs",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "Upgrade",
				"port": "versions"
			},
			"tgt": {
				"process": "CreateSpecs",
				"port": "db"
			}
		},
		{
			"src": {
				"process": "CreateSpecs",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "SpecProject",
				"port": "name"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "SpecProject",
				"port": "keypath"
			}
		},
		{
			"src": {
				"process": "CreateSpecs",
				"port": "store"
			},
			"tgt": {
				"process": "SpecProject",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "SpecProject",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Open",
				"port": "db"
			},
			"tgt": {
				"process": "DatabaseReady",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		}
	},
	"caseSensitive": false
};