module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"context": {
			"process": "MergeContext",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"CleanEdges": {
			"component": "ui/CleanAction"
		},
		"CreateEdgeCtx": {
			"component": "objects/CreateObject"
		},
		"SetEdges": {
			"component": "ui/SetToContext"
		},
		"MergeContext": {
			"component": "core/Merge"
		},
		"CleanNodes": {
			"component": "ui/CleanAction"
		},
		"CreateNodeCtx": {
			"component": "objects/CreateObject"
		},
		"SetNodes": {
			"component": "ui/SetToContext"
		},
		"CleanSearch": {
			"component": "ui/CleanAction"
		},
		"CleanSearchResults": {
			"component": "ui/CleanAction"
		},
		"CleanGraph": {
			"component": "ui/CleanAction"
		},
		"GetGraph": {
			"component": "objects/GetObjectKey"
		},
		"SendGraphKey": {
			"component": "strings/SendString"
		},
		"FindNodesInGraph": {
			"component": "ui/FindNodesInGraph"
		},
		"CleanSearchterm": {
			"component": "ui/CleanAction"
		},
		"GetSearchTerm": {
			"component": "objects/GetObjectKey"
		},
		"SendSearchKey": {
			"component": "strings/SendString"
		},
		"NodesToContext": {
			"component": "ui/SearchGraphNodeToContext"
		}
	},
	"connections": [
		{
			"data": "context:edges,context:nodes,context:search_library,context:search_graph,context:search_library_result,context:graph",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "CleanEdges",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CleanEdges",
				"port": "out"
			},
			"tgt": {
				"process": "CreateEdgeCtx",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "CreateEdgeCtx",
				"port": "out"
			},
			"tgt": {
				"process": "SetEdges",
				"port": "context"
			}
		},
		{
			"data": "edges",
			"tgt": {
				"process": "SetEdges",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CleanEdges",
				"port": "out"
			},
			"tgt": {
				"process": "SetEdges",
				"port": "value"
			}
		},
		{
			"src": {
				"process": "SetEdges",
				"port": "context"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "CleanNodes",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CleanNodes",
				"port": "out"
			},
			"tgt": {
				"process": "CreateNodeCtx",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "CreateNodeCtx",
				"port": "out"
			},
			"tgt": {
				"process": "SetNodes",
				"port": "context"
			}
		},
		{
			"data": "nodes",
			"tgt": {
				"process": "SetNodes",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CleanNodes",
				"port": "out"
			},
			"tgt": {
				"process": "SetNodes",
				"port": "value"
			}
		},
		{
			"src": {
				"process": "SetNodes",
				"port": "context"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "CleanSearch",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CleanSearch",
				"port": "out"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "CleanSearchResults",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CleanSearchResults",
				"port": "out"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 5
			},
			"tgt": {
				"process": "CleanGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CleanGraph",
				"port": "out"
			},
			"tgt": {
				"process": "GetGraph",
				"port": "in"
			}
		},
		{
			"data": "graph",
			"tgt": {
				"process": "SendGraphKey",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendGraphKey",
				"port": "out"
			},
			"tgt": {
				"process": "GetGraph",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CleanGraph",
				"port": "out"
			},
			"tgt": {
				"process": "SendGraphKey",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGraph",
				"port": "out"
			},
			"tgt": {
				"process": "FindNodesInGraph",
				"port": "graph"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "CleanSearchterm",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CleanSearchterm",
				"port": "out"
			},
			"tgt": {
				"process": "GetSearchTerm",
				"port": "in"
			}
		},
		{
			"data": "search",
			"tgt": {
				"process": "SendSearchKey",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendSearchKey",
				"port": "out"
			},
			"tgt": {
				"process": "GetSearchTerm",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CleanSearchterm",
				"port": "out"
			},
			"tgt": {
				"process": "SendSearchKey",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSearchTerm",
				"port": "out"
			},
			"tgt": {
				"process": "FindNodesInGraph",
				"port": "search"
			}
		},
		{
			"src": {
				"process": "FindNodesInGraph",
				"port": "nodes"
			},
			"tgt": {
				"process": "NodesToContext",
				"port": "nodes"
			}
		},
		{
			"src": {
				"process": "NodesToContext",
				"port": "context"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "search"
	},
	"caseSensitive": false
};