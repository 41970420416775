module.exports = {
	"inports": {
		"token": {
			"process": "Token",
			"port": "in"
		},
		"prepare": {
			"process": "RepoToPrepare",
			"port": "in"
		},
		"sync": {
			"process": "DoSync",
			"port": "in"
		},
		"pull": {
			"process": "PullRepo",
			"port": "in"
		}
	},
	"outports": {
		"operation": {
			"process": "MergeOps",
			"port": "out"
		},
		"component": {
			"process": "UpdatedComponent",
			"port": "out"
		},
		"graph": {
			"process": "UpdatedGraph",
			"port": "out"
		},
		"spec": {
			"process": "UpdatedSpec",
			"port": "out"
		},
		"project": {
			"process": "BlobToEntry",
			"port": "project"
		},
		"loading": {
			"process": "Loading",
			"port": "out"
		},
		"ready": {
			"process": "Ready",
			"port": "out"
		},
		"error": {
			"process": "Errors",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"SendRepoKey": {
			"component": "strings/SendString"
		},
		"GetRepoToPrepare": {
			"component": "objects/GetObjectKey"
		},
		"RepoToPrepare": {
			"component": "core/Split"
		},
		"Token": {
			"component": "core/Repeat"
		},
		"GetReference": {
			"component": "ui/GetReference"
		},
		"CheckSyncState": {
			"component": "ui/CheckSyncState"
		},
		"SendProjectKey": {
			"component": "strings/SendString"
		},
		"GetProject": {
			"component": "objects/GetObjectKey"
		},
		"Errors": {
			"component": "core/Merge"
		},
		"MergeOps": {
			"component": "core/Merge"
		},
		"DoSync": {
			"component": "core/Repeat"
		},
		"PreparePulls": {
			"component": "ui/PreparePulls"
		},
		"GetBlob": {
			"component": "ui/GetBlob"
		},
		"BlobToEntry": {
			"component": "ui/BlobToEntry"
		},
		"CompletePulls": {
			"component": "flow/Race"
		},
		"UpdatedComponent": {
			"component": "core/Merge"
		},
		"UpdatedGraph": {
			"component": "core/Merge"
		},
		"UpdatedSpec": {
			"component": "core/Merge"
		},
		"Ready": {
			"component": "core/Repeat"
		},
		"PreparePush": {
			"component": "ui/PreparePush"
		},
		"UpdateFromTree": {
			"component": "ui/UpdateFromTree"
		},
		"CreateTree": {
			"component": "ui/CreateTree"
		},
		"SendTreeSha": {
			"component": "strings/SendString"
		},
		"GetTreeSha": {
			"component": "objects/GetObjectKey"
		},
		"CreateCommit": {
			"component": "ui/CreateCommit"
		},
		"UpdateReference": {
			"component": "ui/UpdateReference"
		},
		"SendPullRepo": {
			"component": "strings/SendString"
		},
		"GetPullRepo": {
			"component": "objects/GetObjectKey"
		},
		"PullRepo": {
			"component": "core/Split"
		},
		"Loading": {
			"component": "core/Repeat"
		},
		"GetPullReference": {
			"component": "ui/GetReference"
		},
		"CheckPullState": {
			"component": "ui/CheckSyncState"
		},
		"SendPullProject": {
			"component": "strings/SendString"
		},
		"GetPullProject": {
			"component": "objects/GetObjectKey"
		}
	},
	"connections": [
		{
			"data": "repo",
			"tgt": {
				"process": "SendRepoKey",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendRepoKey",
				"port": "out"
			},
			"tgt": {
				"process": "GetRepoToPrepare",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "RepoToPrepare",
				"port": "out"
			},
			"tgt": {
				"process": "GetRepoToPrepare",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RepoToPrepare",
				"port": "out"
			},
			"tgt": {
				"process": "SendRepoKey",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "GetReference",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetRepoToPrepare",
				"port": "out"
			},
			"tgt": {
				"process": "GetReference",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "GetReference",
				"port": "reference"
			},
			"tgt": {
				"process": "CheckSyncState",
				"port": "reference"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "SendProjectKey",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendProjectKey",
				"port": "out"
			},
			"tgt": {
				"process": "GetProject",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "GetRepoToPrepare",
				"port": "object"
			},
			"tgt": {
				"process": "GetProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetProject",
				"port": "out"
			},
			"tgt": {
				"process": "CheckSyncState",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "GetRepoToPrepare",
				"port": "object"
			},
			"tgt": {
				"process": "SendProjectKey",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "CheckSyncState",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetReference",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckSyncState",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckSyncState",
				"port": "noop"
			},
			"tgt": {
				"process": "MergeOps",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckSyncState",
				"port": "local"
			},
			"tgt": {
				"process": "MergeOps",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckSyncState",
				"port": "remote"
			},
			"tgt": {
				"process": "MergeOps",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckSyncState",
				"port": "both"
			},
			"tgt": {
				"process": "MergeOps",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DoSync",
				"port": "out"
			},
			"tgt": {
				"process": "PreparePulls",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "GetBlob",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "PreparePulls",
				"port": "out"
			},
			"tgt": {
				"process": "BlobToEntry",
				"port": "operation"
			}
		},
		{
			"src": {
				"process": "PreparePulls",
				"port": "repository"
			},
			"tgt": {
				"process": "GetBlob",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "PreparePulls",
				"port": "sha"
			},
			"tgt": {
				"process": "GetBlob",
				"port": "sha"
			}
		},
		{
			"src": {
				"process": "GetBlob",
				"port": "out"
			},
			"tgt": {
				"process": "CompletePulls",
				"port": "in",
				"index": 0
			}
		},
		{
			"src": {
				"process": "CompletePulls",
				"port": "out"
			},
			"tgt": {
				"process": "BlobToEntry",
				"port": "blob"
			}
		},
		{
			"src": {
				"process": "GetBlob",
				"port": "error"
			},
			"tgt": {
				"process": "CompletePulls",
				"port": "error"
			}
		},
		{
			"src": {
				"process": "CompletePulls",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "BlobToEntry",
				"port": "component"
			},
			"tgt": {
				"process": "UpdatedComponent",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "BlobToEntry",
				"port": "graph"
			},
			"tgt": {
				"process": "UpdatedGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "BlobToEntry",
				"port": "spec"
			},
			"tgt": {
				"process": "UpdatedSpec",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "BlobToEntry",
				"port": "project"
			},
			"tgt": {
				"process": "Ready",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "BlobToEntry",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DoSync",
				"port": "out"
			},
			"tgt": {
				"process": "PreparePush",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "out"
			},
			"tgt": {
				"process": "UpdateFromTree",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "basetree"
			},
			"tgt": {
				"process": "CreateTree",
				"port": "base"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "CreateTree",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "tree"
			},
			"tgt": {
				"process": "CreateTree",
				"port": "tree"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "repository"
			},
			"tgt": {
				"process": "CreateTree",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "CreateTree",
				"port": "out"
			},
			"tgt": {
				"process": "UpdateFromTree",
				"port": "tree"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "repository"
			},
			"tgt": {
				"process": "UpdateFromTree",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "UpdateFromTree",
				"port": "token"
			}
		},
		{
			"data": "sha",
			"tgt": {
				"process": "SendTreeSha",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendTreeSha",
				"port": "out"
			},
			"tgt": {
				"process": "GetTreeSha",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CreateTree",
				"port": "out"
			},
			"tgt": {
				"process": "GetTreeSha",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CreateTree",
				"port": "out"
			},
			"tgt": {
				"process": "SendTreeSha",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetTreeSha",
				"port": "out"
			},
			"tgt": {
				"process": "CreateCommit",
				"port": "tree"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "CreateCommit",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "message"
			},
			"tgt": {
				"process": "CreateCommit",
				"port": "message"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "parentcommits"
			},
			"tgt": {
				"process": "CreateCommit",
				"port": "parents"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "repository"
			},
			"tgt": {
				"process": "CreateCommit",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "CreateCommit",
				"port": "out"
			},
			"tgt": {
				"process": "UpdateReference",
				"port": "commit"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "ref"
			},
			"tgt": {
				"process": "UpdateReference",
				"port": "reference"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "UpdateReference",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "PreparePush",
				"port": "repository"
			},
			"tgt": {
				"process": "UpdateReference",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "UpdateFromTree",
				"port": "component"
			},
			"tgt": {
				"process": "UpdatedComponent",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UpdateFromTree",
				"port": "graph"
			},
			"tgt": {
				"process": "UpdatedGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UpdateFromTree",
				"port": "spec"
			},
			"tgt": {
				"process": "UpdatedSpec",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UpdateFromTree",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CreateTree",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CreateCommit",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UpdateReference",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "repo",
			"tgt": {
				"process": "SendPullRepo",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendPullRepo",
				"port": "out"
			},
			"tgt": {
				"process": "GetPullRepo",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "PullRepo",
				"port": "out"
			},
			"tgt": {
				"process": "Loading",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PullRepo",
				"port": "out"
			},
			"tgt": {
				"process": "GetPullRepo",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PullRepo",
				"port": "out"
			},
			"tgt": {
				"process": "SendPullRepo",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "GetPullReference",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetPullRepo",
				"port": "out"
			},
			"tgt": {
				"process": "GetPullReference",
				"port": "repository"
			}
		},
		{
			"src": {
				"process": "GetPullReference",
				"port": "reference"
			},
			"tgt": {
				"process": "CheckPullState",
				"port": "reference"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "SendPullProject",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SendPullProject",
				"port": "out"
			},
			"tgt": {
				"process": "GetPullProject",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "GetPullRepo",
				"port": "object"
			},
			"tgt": {
				"process": "GetPullProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetPullProject",
				"port": "out"
			},
			"tgt": {
				"process": "CheckPullState",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "GetPullRepo",
				"port": "object"
			},
			"tgt": {
				"process": "SendPullProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Token",
				"port": "out"
			},
			"tgt": {
				"process": "CheckPullState",
				"port": "token"
			}
		},
		{
			"src": {
				"process": "GetPullReference",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckPullState",
				"port": "remote"
			},
			"tgt": {
				"process": "DoSync",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "CheckPullState",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "github-alt"
	},
	"caseSensitive": false
};