module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"pass": {
			"process": "Passed",
			"port": "out"
		},
		"new": {
			"process": "NewActions",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"Passed": {
			"component": "core/Repeat"
		},
		"ApplicationUrlAction": {
			"component": "ui/SetAction"
		},
		"NewActions": {
			"component": "core/Merge"
		},
		"ApplicationRedirectAction": {
			"component": "ui/SetAction"
		},
		"ApplicationHashAction": {
			"component": "ui/SetAction"
		},
		"NofloReady": {
			"component": "core/Split"
		},
		"InitialURL": {
			"component": "ui/GetUrl"
		},
		"SplitReady": {
			"component": "core/Split"
		},
		"Async": {
			"component": "core/RepeatAsync"
		},
		"ListenHash": {
			"component": "interaction/ListenHash"
		},
		"Redirect": {
			"component": "ui/SetUrl"
		},
		"HashToAction": {
			"component": "ui/Router"
		},
		"SetUrlHash": {
			"component": "ui/SetUrlHash"
		},
		"SendCurrentHash": {
			"component": "ui/SendCurrentHash"
		}
	},
	"connections": [
		{
			"data": "noflo:ready,storage:ready,application:redirect,application:hash,application:sethash,application:recreatestate",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "application:url",
			"tgt": {
				"process": "ApplicationUrlAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationUrlAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "application:redirect",
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationRedirectAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "application:hash",
			"tgt": {
				"process": "ApplicationHashAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationHashAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "NofloReady",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "NofloReady",
				"port": "out"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "NofloReady",
				"port": "out"
			},
			"tgt": {
				"process": "InitialURL",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "InitialURL",
				"port": "out"
			},
			"tgt": {
				"process": "ApplicationUrlAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenHash",
				"port": "initial"
			},
			"tgt": {
				"process": "ApplicationHashAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenHash",
				"port": "change"
			},
			"tgt": {
				"process": "ApplicationHashAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "SplitReady",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitReady",
				"port": "out"
			},
			"tgt": {
				"process": "Async",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Async",
				"port": "out"
			},
			"tgt": {
				"process": "ListenHash",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "SplitReady",
				"port": "out"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "Redirect",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "HashToAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "HashToAction",
				"port": "route"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "HashToAction",
				"port": "redirect"
			},
			"tgt": {
				"process": "ApplicationRedirectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "SetUrlHash",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 5
			},
			"tgt": {
				"process": "SendCurrentHash",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SendCurrentHash",
				"port": "out"
			},
			"tgt": {
				"process": "ApplicationHashAction",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "slack"
	},
	"caseSensitive": false
};