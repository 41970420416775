module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"project": {
			"process": "LoadProjects",
			"port": "item"
		},
		"graph": {
			"process": "ConvertGraph",
			"port": "out"
		},
		"component": {
			"process": "LoadComponents",
			"port": "item"
		},
		"spec": {
			"process": "LoadSpecs",
			"port": "item"
		},
		"runtime": {
			"process": "Runtimes",
			"port": "out"
		},
		"complete": {
			"process": "Collect",
			"port": "out"
		},
		"error": {
			"process": "Errors",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"GetProjectsDb": {
			"component": "ui/GetActionValues"
		},
		"LoadProjects": {
			"component": "ui/Load"
		},
		"ProjectsStore": {
			"component": "strings/SendString"
		},
		"GetGraphsDb": {
			"component": "ui/GetActionValues"
		},
		"LoadGraphs": {
			"component": "ui/Load"
		},
		"GraphsStore": {
			"component": "strings/SendString"
		},
		"ConvertGraph": {
			"component": "graph/LoadJson"
		},
		"GetComponentsDb": {
			"component": "ui/GetActionValues"
		},
		"LoadComponents": {
			"component": "ui/Load"
		},
		"ComponentsStore": {
			"component": "strings/SendString"
		},
		"GetSpecsDb": {
			"component": "ui/GetActionValues"
		},
		"LoadSpecs": {
			"component": "ui/Load"
		},
		"SpecsStore": {
			"component": "strings/SendString"
		},
		"GetRuntimesDb": {
			"component": "ui/GetActionValues"
		},
		"LoadRuntimes": {
			"component": "ui/Load"
		},
		"RuntimesStore": {
			"component": "strings/SendString"
		},
		"Runtimes": {
			"component": "core/Merge"
		},
		"SplitAction": {
			"component": "core/Split"
		},
		"GetAllRuntimesDb": {
			"component": "ui/GetActionValues"
		},
		"LoadAllRuntimes": {
			"component": "ui/Load"
		},
		"AllRuntimesStore": {
			"component": "strings/SendString"
		},
		"Collect": {
			"component": "ui/CollectInitialData"
		},
		"Errors": {
			"component": "core/Merge"
		}
	},
	"connections": [
		{
			"data": "storage:load:projects,storage:load:graphs,storage:load:components,storage:load:specs,storage:load:runtimes,storage:load:all",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetProjectsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "LoadProjects",
				"port": "db"
			}
		},
		{
			"data": "projects",
			"tgt": {
				"process": "ProjectsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "GetProjectsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ProjectsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ProjectsStore",
				"port": "out"
			},
			"tgt": {
				"process": "LoadProjects",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "LoadProjects",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetGraphsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "LoadGraphs",
				"port": "db"
			}
		},
		{
			"data": "graphs",
			"tgt": {
				"process": "GraphsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "GetGraphsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "out"
			},
			"tgt": {
				"process": "GraphsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GraphsStore",
				"port": "out"
			},
			"tgt": {
				"process": "LoadGraphs",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "LoadGraphs",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadGraphs",
				"port": "item"
			},
			"tgt": {
				"process": "ConvertGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ConvertGraph",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetComponentsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "LoadComponents",
				"port": "db"
			}
		},
		{
			"data": "components",
			"tgt": {
				"process": "ComponentsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "GetComponentsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ComponentsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ComponentsStore",
				"port": "out"
			},
			"tgt": {
				"process": "LoadComponents",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "LoadComponents",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetSpecsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "LoadSpecs",
				"port": "db"
			}
		},
		{
			"data": "specs",
			"tgt": {
				"process": "SpecsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetSpecsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "out"
			},
			"tgt": {
				"process": "SpecsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SpecsStore",
				"port": "out"
			},
			"tgt": {
				"process": "LoadSpecs",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "LoadSpecs",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetRuntimesDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "LoadRuntimes",
				"port": "db"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "RuntimesStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "GetRuntimesDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimesStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RuntimesStore",
				"port": "out"
			},
			"tgt": {
				"process": "LoadRuntimes",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "LoadRuntimes",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadRuntimes",
				"port": "item"
			},
			"tgt": {
				"process": "Runtimes",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 5
			},
			"tgt": {
				"process": "SplitAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitAction",
				"port": "out"
			},
			"tgt": {
				"process": "GetProjectsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitAction",
				"port": "out"
			},
			"tgt": {
				"process": "GetGraphsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitAction",
				"port": "out"
			},
			"tgt": {
				"process": "GetComponentsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitAction",
				"port": "out"
			},
			"tgt": {
				"process": "GetSpecsDb",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetAllRuntimesDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetAllRuntimesDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "LoadAllRuntimes",
				"port": "db"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "AllRuntimesStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "SplitAction",
				"port": "out"
			},
			"tgt": {
				"process": "GetAllRuntimesDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetAllRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "AllRuntimesStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "AllRuntimesStore",
				"port": "out"
			},
			"tgt": {
				"process": "LoadAllRuntimes",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "LoadAllRuntimes",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadAllRuntimes",
				"port": "item"
			},
			"tgt": {
				"process": "Runtimes",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadProjects",
				"port": "item"
			},
			"tgt": {
				"process": "Collect",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "LoadProjects",
				"port": "empty"
			},
			"tgt": {
				"process": "Collect",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "ConvertGraph",
				"port": "out"
			},
			"tgt": {
				"process": "Collect",
				"port": "graph"
			}
		},
		{
			"src": {
				"process": "LoadGraphs",
				"port": "empty"
			},
			"tgt": {
				"process": "Collect",
				"port": "graph"
			}
		},
		{
			"src": {
				"process": "LoadComponents",
				"port": "item"
			},
			"tgt": {
				"process": "Collect",
				"port": "component"
			}
		},
		{
			"src": {
				"process": "LoadComponents",
				"port": "empty"
			},
			"tgt": {
				"process": "Collect",
				"port": "component"
			}
		},
		{
			"src": {
				"process": "LoadSpecs",
				"port": "item"
			},
			"tgt": {
				"process": "Collect",
				"port": "spec"
			}
		},
		{
			"src": {
				"process": "LoadSpecs",
				"port": "empty"
			},
			"tgt": {
				"process": "Collect",
				"port": "spec"
			}
		},
		{
			"src": {
				"process": "Runtimes",
				"port": "out"
			},
			"tgt": {
				"process": "Collect",
				"port": "runtime"
			}
		},
		{
			"src": {
				"process": "LoadAllRuntimes",
				"port": "empty"
			},
			"tgt": {
				"process": "Collect",
				"port": "runtime"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		}
	},
	"caseSensitive": false
};