module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"context": {
			"process": "MergeContext",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"CleanUser": {
			"component": "ui/CleanAction"
		},
		"UserToContext": {
			"component": "ui/SetToContext"
		},
		"CreateCtx": {
			"component": "ui/CreateEmptyContext"
		},
		"MergeContext": {
			"component": "core/Merge"
		},
		"ErrorToCtx": {
			"component": "ui/ErrorToContext"
		}
	},
	"connections": [
		{
			"data": "user:info,user:error",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "CleanUser",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "UserToContext",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CleanUser",
				"port": "out"
			},
			"tgt": {
				"process": "UserToContext",
				"port": "value"
			}
		},
		{
			"src": {
				"process": "CleanUser",
				"port": "out"
			},
			"tgt": {
				"process": "CreateCtx",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "CreateCtx",
				"port": "out"
			},
			"tgt": {
				"process": "UserToContext",
				"port": "context"
			}
		},
		{
			"src": {
				"process": "UserToContext",
				"port": "context"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "ErrorToCtx",
				"port": "error"
			}
		},
		{
			"src": {
				"process": "ErrorToCtx",
				"port": "out"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "user-circle"
	},
	"caseSensitive": false
};