module.exports = {
	"inports": {
		"db": {
			"process": "Read",
			"port": "db"
		},
		"store": {
			"process": "Store",
			"port": "in"
		}
	},
	"outports": {
		"item": {
			"process": "Query",
			"port": "item"
		},
		"empty": {
			"process": "Query",
			"port": "empty"
		},
		"error": {
			"process": "Errors",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"HoldQuery": {
			"component": "strings/SendString"
		},
		"HoldMode": {
			"component": "strings/SendString"
		},
		"Store": {
			"component": "core/Repeat"
		},
		"Read": {
			"component": "indexeddb/BeginTransaction"
		},
		"GetStore": {
			"component": "indexeddb/GetStore"
		},
		"Query": {
			"component": "indexeddb/Query"
		},
		"Errors": {
			"component": "core/Merge"
		}
	},
	"connections": [
		{
			"data": "",
			"tgt": {
				"process": "HoldQuery",
				"port": "string"
			}
		},
		{
			"data": "readonly",
			"tgt": {
				"process": "HoldMode",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "HoldQuery",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "HoldMode",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "HoldMode",
				"port": "out"
			},
			"tgt": {
				"process": "Read",
				"port": "mode"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "Read",
				"port": "stores"
			}
		},
		{
			"src": {
				"process": "Store",
				"port": "out"
			},
			"tgt": {
				"process": "GetStore",
				"port": "name"
			}
		},
		{
			"src": {
				"process": "Read",
				"port": "transaction"
			},
			"tgt": {
				"process": "GetStore",
				"port": "transaction"
			}
		},
		{
			"src": {
				"process": "GetStore",
				"port": "store"
			},
			"tgt": {
				"process": "Query",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "HoldQuery",
				"port": "out"
			},
			"tgt": {
				"process": "Query",
				"port": "all"
			}
		},
		{
			"src": {
				"process": "Read",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetStore",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Query",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		}
	},
	"caseSensitive": false
};