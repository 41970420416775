module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"context": {
			"process": "MergeContext",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"CleanOps": {
			"component": "ui/CleanAction"
		},
		"OpsToContext": {
			"component": "ui/SetToContext"
		},
		"CreateCtx": {
			"component": "ui/CreateEmptyContext"
		},
		"MergeContext": {
			"component": "core/Merge"
		},
		"ErrorToCtx": {
			"component": "ui/ErrorToContext"
		},
		"Loading": {
			"component": "ui/CreateLoadingContext"
		},
		"Ready": {
			"component": "ui/CreateEmptyContext"
		}
	},
	"connections": [
		{
			"data": "github:operation,github:error,github:loading,github:ready",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "CleanOps",
				"port": "in"
			}
		},
		{
			"data": "syncOperation",
			"tgt": {
				"process": "OpsToContext",
				"port": "key"
			}
		},
		{
			"src": {
				"process": "CleanOps",
				"port": "out"
			},
			"tgt": {
				"process": "OpsToContext",
				"port": "value"
			}
		},
		{
			"src": {
				"process": "CleanOps",
				"port": "out"
			},
			"tgt": {
				"process": "CreateCtx",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "CreateCtx",
				"port": "out"
			},
			"tgt": {
				"process": "OpsToContext",
				"port": "context"
			}
		},
		{
			"src": {
				"process": "OpsToContext",
				"port": "context"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "ErrorToCtx",
				"port": "error"
			}
		},
		{
			"src": {
				"process": "ErrorToCtx",
				"port": "out"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "Loading",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "Loading",
				"port": "out"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "Ready",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "Ready",
				"port": "out"
			},
			"tgt": {
				"process": "MergeContext",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "github"
	},
	"caseSensitive": false
};