module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"pass": {
			"process": "Passed",
			"port": "out"
		},
		"new": {
			"process": "NewActions",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"Passed": {
			"component": "core/Repeat"
		},
		"RuntimeSaveAction": {
			"component": "ui/SetAction"
		},
		"NewActions": {
			"component": "core/Merge"
		},
		"RuntimeDeleteAction": {
			"component": "ui/SetAction"
		},
		"RegistryProjectsAction": {
			"component": "ui/SetAction"
		},
		"RegistryErrorAction": {
			"component": "ui/SetAction"
		},
		"GetUserStateFetch": {
			"component": "ui/GetActionValues"
		},
		"GetRuntimes": {
			"component": "ui/GetRemoteRuntimes"
		},
		"GetUserStatePut": {
			"component": "ui/GetActionValues"
		},
		"SaveRuntime": {
			"component": "ui/StoreRemoteRuntime"
		},
		"GetUserStateDel": {
			"component": "ui/GetActionValues"
		},
		"RemoveRuntime": {
			"component": "ui/RemoveRemoteRuntime"
		},
		"GetUserStateProjects": {
			"component": "ui/GetActionValues"
		},
		"GetRemoteProjects": {
			"component": "ui/GetRemoteProjects"
		},
		"GetUserStateRepos": {
			"component": "ui/GetActionValues"
		},
		"SplitPullAction": {
			"component": "core/Split"
		},
		"SendPullAction": {
			"component": "core/Kick"
		},
		"StoreRemoteProject": {
			"component": "ui/StoreRemoteProject"
		},
		"GetUserStateDelRepo": {
			"component": "ui/GetActionValues"
		},
		"RemoveRepository": {
			"component": "ui/RemoveRemoteProject"
		}
	},
	"connections": [
		{
			"data": "flowhub:runtimes:fetch,flowhub:runtimes:register,flowhub:runtimes:remove,flowhub:projects:fetch,github:sync:pull,github:sync:prepare,flowhub:projects:remove",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "storage:save:runtime",
			"tgt": {
				"process": "RuntimeSaveAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeSaveAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:delete:runtime",
			"tgt": {
				"process": "RuntimeDeleteAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeDeleteAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "flowhub:projects",
			"tgt": {
				"process": "RegistryProjectsAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RegistryProjectsAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "flowhub:error",
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RegistryErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "user,runtimes",
			"tgt": {
				"process": "GetUserStateFetch",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "GetUserStateFetch",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStateFetch",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "GetRuntimes",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetUserStateFetch",
				"port": "values",
				"index": 1
			},
			"tgt": {
				"process": "GetRuntimes",
				"port": "runtimes"
			}
		},
		{
			"src": {
				"process": "GetRuntimes",
				"port": "runtime"
			},
			"tgt": {
				"process": "RuntimeSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimes",
				"port": "error"
			},
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "GetUserStatePut",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "GetUserStatePut",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStatePut",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SaveRuntime",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetUserStatePut",
				"port": "out"
			},
			"tgt": {
				"process": "SaveRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveRuntime",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveRuntime",
				"port": "error"
			},
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "GetUserStateDel",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "GetUserStateDel",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStateDel",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "RemoveRuntime",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetUserStateDel",
				"port": "out"
			},
			"tgt": {
				"process": "RemoveRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RemoveRuntime",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeDeleteAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RemoveRuntime",
				"port": "error"
			},
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "GetUserStateProjects",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetUserStateProjects",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStateProjects",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "GetRemoteProjects",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetRemoteProjects",
				"port": "projects"
			},
			"tgt": {
				"process": "RegistryProjectsAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRemoteProjects",
				"port": "error"
			},
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "GetUserStateRepos",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "SplitPullAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitPullAction",
				"port": "out"
			},
			"tgt": {
				"process": "GetUserStateRepos",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitPullAction",
				"port": "out"
			},
			"tgt": {
				"process": "SendPullAction",
				"port": "data"
			}
		},
		{
			"src": {
				"process": "SendPullAction",
				"port": "out"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStateRepos",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "StoreRemoteProject",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetUserStateRepos",
				"port": "out"
			},
			"tgt": {
				"process": "StoreRemoteProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreRemoteProject",
				"port": "out"
			},
			"tgt": {
				"process": "SendPullAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StoreRemoteProject",
				"port": "error"
			},
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 5
			},
			"tgt": {
				"process": "StoreRemoteProject",
				"port": "in"
			}
		},
		{
			"data": "user",
			"tgt": {
				"process": "GetUserStateDelRepo",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 6
			},
			"tgt": {
				"process": "GetUserStateDelRepo",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetUserStateDelRepo",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "RemoveRepository",
				"port": "user"
			}
		},
		{
			"src": {
				"process": "GetUserStateDelRepo",
				"port": "out"
			},
			"tgt": {
				"process": "RemoveRepository",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RemoveRepository",
				"port": "error"
			},
			"tgt": {
				"process": "RegistryErrorAction",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "address-card-o"
	},
	"caseSensitive": false
};