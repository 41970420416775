module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"pass": {
			"process": "Passed",
			"port": "out"
		},
		"new": {
			"process": "NewActions",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"Passed": {
			"component": "core/Merge"
		},
		"ProjectSaveAction": {
			"component": "ui/SetAction"
		},
		"NewActions": {
			"component": "core/Merge"
		},
		"GraphSaveAction": {
			"component": "ui/SetAction"
		},
		"ComponentSaveAction": {
			"component": "ui/SetAction"
		},
		"SpecSaveAction": {
			"component": "ui/SetAction"
		},
		"RuntimeRegisterAction": {
			"component": "ui/SetAction"
		},
		"RuntimeSaveAction": {
			"component": "ui/SetAction"
		},
		"RuntimeErrorAction": {
			"component": "ui/SetAction"
		},
		"RuntimeOpenedAction": {
			"component": "ui/SetAction"
		},
		"RuntimeOpeningAction": {
			"component": "ui/SetAction"
		},
		"RuntimeStatusAction": {
			"component": "ui/SetAction"
		},
		"RuntimeComponentsAction": {
			"component": "ui/SetAction"
		},
		"RuntimeComponentAction": {
			"component": "ui/SetAction"
		},
		"RuntimeStartedAction": {
			"component": "ui/SetAction"
		},
		"RuntimeStoppedAction": {
			"component": "ui/SetAction"
		},
		"RuntimePacketAction": {
			"component": "ui/SetAction"
		},
		"RuntimeIconAction": {
			"component": "ui/SetAction"
		},
		"RuntimeOutputAction": {
			"component": "ui/SetAction"
		},
		"RuntimeNetworkErrorAction": {
			"component": "ui/SetAction"
		},
		"RuntimeProcessErrorAction": {
			"component": "ui/SetAction"
		},
		"RuntimeProtocolErrorAction": {
			"component": "ui/SetAction"
		},
		"RuntimeLoadTestsAction": {
			"component": "ui/SetAction"
		},
		"RuntimeTestSuitesAction": {
			"component": "ui/SetAction"
		},
		"ApplicationRecreateAction": {
			"component": "ui/SetAction"
		},
		"StorageOpenedAction": {
			"component": "ui/SetAction"
		},
		"StorageReadyAction": {
			"component": "ui/SetAction"
		},
		"ContextEdgesAction": {
			"component": "ui/SetAction"
		},
		"GetRuntimesState": {
			"component": "ui/GetActionValues"
		},
		"PopulateRuntimeData": {
			"component": "ui/PopulateRuntimeData"
		},
		"OpenLiveMode": {
			"component": "ui/OpenLiveMode"
		},
		"GetProjectRuntimesState": {
			"component": "ui/GetActionValues"
		},
		"PopulateProjectRuntime": {
			"component": "ui/PopulateProjectRuntime"
		},
		"OpenProjectMode": {
			"component": "ui/OpenProjectMode"
		},
		"GetEdgeState": {
			"component": "ui/GetActionValues"
		},
		"HasEdgeRuntime": {
			"component": "ui/HasActionRuntime"
		},
		"SendEdges": {
			"component": "ui/SendEdges"
		},
		"GetRuntimeToProjectValues": {
			"component": "ui/GetActionValues"
		},
		"RuntimeToProject": {
			"component": "ui/RuntimeToProject"
		},
		"GetStoredRuntimes": {
			"component": "ui/GetActionValues"
		},
		"EnsureLocalRuntimes": {
			"component": "ui/EnsureLocalRuntimes"
		},
		"GetClient": {
			"component": "ui/GetRuntimeClient"
		},
		"ListenRuntime": {
			"component": "ui/ListenRuntime"
		},
		"GetStartValues": {
			"component": "ui/GetActionValues"
		},
		"StartNetwork": {
			"component": "ui/StartNetwork"
		},
		"GetStopValues": {
			"component": "ui/GetActionValues"
		},
		"StopNetwork": {
			"component": "ui/StopNetwork"
		},
		"GetReconnectValues": {
			"component": "ui/GetActionValues"
		},
		"ReconnectRuntime": {
			"component": "ui/ReconnectRuntime"
		},
		"GetComponentValues": {
			"component": "ui/GetActionValues"
		},
		"SendComponentChanges": {
			"component": "ui/SendComponentChanges"
		},
		"GetGraphChangeValues": {
			"component": "ui/GetActionValues"
		},
		"SendGraphChanges": {
			"component": "ui/SendGraphChanges"
		},
		"GetNewGraphValues": {
			"component": "ui/GetActionValues"
		},
		"SendNewGraph": {
			"component": "ui/SendNewGraph"
		},
		"GetSubgraphValues": {
			"component": "ui/GetActionValues"
		},
		"SendSubgraph": {
			"component": "ui/SendSubgraph"
		},
		"GetLoadTestsValues": {
			"component": "ui/GetActionValues"
		},
		"LoadTests": {
			"component": "ui/LoadTests"
		},
		"Async": {
			"component": "core/RepeatAsync"
		},
		"GetRunTestsValues": {
			"component": "ui/GetActionValues"
		},
		"RunTests": {
			"component": "ui/RunTests"
		}
	},
	"connections": [
		{
			"data": "runtime:open,storage:opened,context:edges,project:fromruntime,storage:ready,storage:stored:runtime,runtime:start,runtime:stop,runtime:reconnect,runtime:sendComponent,runtime:sendGraphChanges,runtime:sendGraph,runtime:sendSubgraph,runtime:loadTests,runtime:runTests",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "storage:save:project",
			"tgt": {
				"process": "ProjectSaveAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ProjectSaveAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:graph",
			"tgt": {
				"process": "GraphSaveAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "GraphSaveAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:component",
			"tgt": {
				"process": "ComponentSaveAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ComponentSaveAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:spec",
			"tgt": {
				"process": "SpecSaveAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "SpecSaveAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "flowhub:runtimes:register",
			"tgt": {
				"process": "RuntimeRegisterAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeRegisterAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:save:runtime",
			"tgt": {
				"process": "RuntimeSaveAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeSaveAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:error",
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:opened",
			"tgt": {
				"process": "RuntimeOpenedAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeOpenedAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:opening",
			"tgt": {
				"process": "RuntimeOpeningAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeOpeningAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:status",
			"tgt": {
				"process": "RuntimeStatusAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeStatusAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:components",
			"tgt": {
				"process": "RuntimeComponentsAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeComponentsAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:component",
			"tgt": {
				"process": "RuntimeComponentAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeComponentAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:started",
			"tgt": {
				"process": "RuntimeStartedAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeStartedAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:stopped",
			"tgt": {
				"process": "RuntimeStoppedAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeStoppedAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:packet",
			"tgt": {
				"process": "RuntimePacketAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimePacketAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:icon",
			"tgt": {
				"process": "RuntimeIconAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeIconAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:output",
			"tgt": {
				"process": "RuntimeOutputAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeOutputAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:networkerror",
			"tgt": {
				"process": "RuntimeNetworkErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeNetworkErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:processerror",
			"tgt": {
				"process": "RuntimeProcessErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeProcessErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:protocolerror",
			"tgt": {
				"process": "RuntimeProtocolErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeProtocolErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:loadTests",
			"tgt": {
				"process": "RuntimeLoadTestsAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeLoadTestsAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:testsuites",
			"tgt": {
				"process": "RuntimeTestSuitesAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeTestSuitesAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "application:recreatestate",
			"tgt": {
				"process": "ApplicationRecreateAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationRecreateAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:opened",
			"tgt": {
				"process": "StorageOpenedAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageOpenedAction",
				"port": "out"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "storage:ready",
			"tgt": {
				"process": "StorageReadyAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageReadyAction",
				"port": "out"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "context:edges",
			"tgt": {
				"process": "ContextEdgesAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ContextEdgesAction",
				"port": "out"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "runtimes,projects",
			"tgt": {
				"process": "GetRuntimesState",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "GetRuntimesState",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimesState",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "PopulateRuntimeData",
				"port": "runtimes"
			}
		},
		{
			"src": {
				"process": "GetRuntimesState",
				"port": "out"
			},
			"tgt": {
				"process": "PopulateRuntimeData",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PopulateRuntimeData",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 0
			}
		},
		{
			"src": {
				"process": "PopulateRuntimeData",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeOpeningAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PopulateRuntimeData",
				"port": "new"
			},
			"tgt": {
				"process": "GetClient",
				"port": "updated"
			}
		},
		{
			"src": {
				"process": "PopulateRuntimeData",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 0
			},
			"tgt": {
				"process": "OpenLiveMode",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimesState",
				"port": "values",
				"index": 1
			},
			"tgt": {
				"process": "OpenLiveMode",
				"port": "projects"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 0
			},
			"tgt": {
				"process": "OpenLiveMode",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeOpenedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeLoadTestsAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "project"
			},
			"tgt": {
				"process": "ProjectSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "graph"
			},
			"tgt": {
				"process": "GraphSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "component"
			},
			"tgt": {
				"process": "ComponentSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "spec"
			},
			"tgt": {
				"process": "SpecSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "runtime"
			},
			"tgt": {
				"process": "RuntimeSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenLiveMode",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "GetProjectRuntimesState",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "GetProjectRuntimesState",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetProjectRuntimesState",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "PopulateProjectRuntime",
				"port": "runtimes"
			}
		},
		{
			"src": {
				"process": "GetProjectRuntimesState",
				"port": "out"
			},
			"tgt": {
				"process": "PopulateProjectRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PopulateProjectRuntime",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 1
			}
		},
		{
			"src": {
				"process": "PopulateProjectRuntime",
				"port": "skipped"
			},
			"tgt": {
				"process": "StorageOpenedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 1
			},
			"tgt": {
				"process": "OpenProjectMode",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 1
			},
			"tgt": {
				"process": "OpenProjectMode",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "OpenProjectMode",
				"port": "out"
			},
			"tgt": {
				"process": "StorageOpenedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenProjectMode",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "graphs,project",
			"tgt": {
				"process": "GetEdgeState",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "HasEdgeRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "HasEdgeRuntime",
				"port": "out"
			},
			"tgt": {
				"process": "GetEdgeState",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "HasEdgeRuntime",
				"port": "missed"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetEdgeState",
				"port": "state"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 2
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 2
			},
			"tgt": {
				"process": "SendEdges",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "GetEdgeState",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SendEdges",
				"port": "graphs"
			}
		},
		{
			"src": {
				"process": "GetEdgeState",
				"port": "values",
				"index": 1
			},
			"tgt": {
				"process": "SendEdges",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "GetEdgeState",
				"port": "out"
			},
			"tgt": {
				"process": "SendEdges",
				"port": "edges"
			}
		},
		{
			"src": {
				"process": "SendEdges",
				"port": "out"
			},
			"tgt": {
				"process": "ContextEdgesAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SendEdges",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetRuntimeToProjectValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimeToProjectValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 3
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 3
			},
			"tgt": {
				"process": "RuntimeToProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 3
			},
			"tgt": {
				"process": "RuntimeToProject",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "RuntimeToProject",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "GetStoredRuntimes",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "GetStoredRuntimes",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetStoredRuntimes",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "EnsureLocalRuntimes",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "EnsureLocalRuntimes",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "EnsureLocalRuntimes",
				"port": "runtimes"
			},
			"tgt": {
				"process": "GetClient",
				"port": "initial"
			}
		},
		{
			"src": {
				"process": "GetStoredRuntimes",
				"port": "out"
			},
			"tgt": {
				"process": "StorageReadyAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "instance"
			},
			"tgt": {
				"process": "ListenRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "runtimeupdate"
			},
			"tgt": {
				"process": "RuntimeRegisterAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "status"
			},
			"tgt": {
				"process": "RuntimeStatusAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "components"
			},
			"tgt": {
				"process": "RuntimeComponentsAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "component"
			},
			"tgt": {
				"process": "RuntimeComponentAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "started"
			},
			"tgt": {
				"process": "RuntimeStartedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "stopped"
			},
			"tgt": {
				"process": "RuntimeStoppedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "packet"
			},
			"tgt": {
				"process": "RuntimePacketAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "icon"
			},
			"tgt": {
				"process": "RuntimeIconAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "output"
			},
			"tgt": {
				"process": "RuntimeOutputAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "networkerror"
			},
			"tgt": {
				"process": "RuntimeNetworkErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "processerror"
			},
			"tgt": {
				"process": "RuntimeProcessErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "protocolerror"
			},
			"tgt": {
				"process": "RuntimeProtocolErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ListenRuntime",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 5
			},
			"tgt": {
				"process": "GetClient",
				"port": "updated"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "updated"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 6
			},
			"tgt": {
				"process": "GetStartValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetStartValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 4
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 4
			},
			"tgt": {
				"process": "StartNetwork",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 4
			},
			"tgt": {
				"process": "StartNetwork",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "StartNetwork",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeStartedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StartNetwork",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 7
			},
			"tgt": {
				"process": "GetStopValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetStopValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 5
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 5
			},
			"tgt": {
				"process": "StopNetwork",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 5
			},
			"tgt": {
				"process": "StopNetwork",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "StopNetwork",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeStoppedAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StopNetwork",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 8
			},
			"tgt": {
				"process": "GetReconnectValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetReconnectValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 6
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 6
			},
			"tgt": {
				"process": "ReconnectRuntime",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 6
			},
			"tgt": {
				"process": "ReconnectRuntime",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "ReconnectRuntime",
				"port": "out"
			},
			"tgt": {
				"process": "ApplicationRecreateAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ReconnectRuntime",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GetComponentValues",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 9
			},
			"tgt": {
				"process": "GetComponentValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetComponentValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 7
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 7
			},
			"tgt": {
				"process": "SendComponentChanges",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 7
			},
			"tgt": {
				"process": "SendComponentChanges",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "GetComponentValues",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SendComponentChanges",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "SendComponentChanges",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeComponentAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SendComponentChanges",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GetGraphChangeValues",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 10
			},
			"tgt": {
				"process": "GetGraphChangeValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGraphChangeValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 8
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 8
			},
			"tgt": {
				"process": "SendGraphChanges",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 8
			},
			"tgt": {
				"process": "SendGraphChanges",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "GetGraphChangeValues",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "SendGraphChanges",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "SendGraphChanges",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GetNewGraphValues",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 11
			},
			"tgt": {
				"process": "GetNewGraphValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetNewGraphValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 9
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 9
			},
			"tgt": {
				"process": "SendNewGraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 9
			},
			"tgt": {
				"process": "SendNewGraph",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "SendNewGraph",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GetSubgraphValues",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 12
			},
			"tgt": {
				"process": "GetSubgraphValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSubgraphValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 10
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 10
			},
			"tgt": {
				"process": "SendSubgraph",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 10
			},
			"tgt": {
				"process": "SendSubgraph",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "SendSubgraph",
				"port": "out"
			},
			"tgt": {
				"process": "GraphSaveAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SendSubgraph",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GetLoadTestsValues",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 13
			},
			"tgt": {
				"process": "GetLoadTestsValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetLoadTestsValues",
				"port": "out"
			},
			"tgt": {
				"process": "LoadTests",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadTests",
				"port": "out"
			},
			"tgt": {
				"process": "Async",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Async",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeTestSuitesAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadTests",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		},
		{
			"data": "project",
			"tgt": {
				"process": "GetRunTestsValues",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 14
			},
			"tgt": {
				"process": "GetRunTestsValues",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRunTestsValues",
				"port": "out"
			},
			"tgt": {
				"process": "GetClient",
				"port": "in",
				"index": 11
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "out",
				"index": 11
			},
			"tgt": {
				"process": "RunTests",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetClient",
				"port": "client",
				"index": 11
			},
			"tgt": {
				"process": "RunTests",
				"port": "client"
			}
		},
		{
			"src": {
				"process": "RunTests",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeTestSuitesAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RunTests",
				"port": "error"
			},
			"tgt": {
				"process": "RuntimeErrorAction",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "cogs"
	},
	"caseSensitive": false
};