module.exports = {
	"inports": {},
	"outports": {},
	"groups": [],
	"processes": {
		"AppView": {
			"component": "polymer/noflo-ui"
		},
		"StoreDispatch": {
			"component": "ui/Store"
		},
		"State": {
			"component": "ui/State"
		},
		"MapStateToProps": {
			"component": "ui/MapStateToProps"
		},
		"Logger": {
			"component": "ui/LoggingMiddleware"
		},
		"UrlMiddleware": {
			"component": "ui/UrlMiddleware"
		},
		"StorageMiddleware": {
			"component": "ui/StorageMiddleware"
		},
		"UserMiddleware": {
			"component": "ui/UserMiddleware"
		},
		"RegistryMiddleware": {
			"component": "ui/RegistryMiddleware"
		},
		"GithubMiddleware": {
			"component": "ui/GithubMiddleware"
		},
		"RuntimeMiddleware": {
			"component": "ui/RuntimeMiddleware"
		},
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"UserReducer": {
			"component": "ui/UserReducer"
		},
		"MainContext": {
			"component": "ui/CreateContext"
		},
		"StorageReducer": {
			"component": "ui/StorageReducer"
		},
		"GithubReducer": {
			"component": "ui/GithubReducer"
		},
		"RegistryReducer": {
			"component": "ui/RegistryReducer"
		},
		"RuntimeReducer": {
			"component": "ui/RuntimeReducer"
		},
		"ContextReducer": {
			"component": "ui/ContextReducer"
		},
		"ShowErrors": {
			"component": "core/Output"
		},
		"ListenWindow": {
			"component": "interaction/ListenResize"
		}
	},
	"connections": [
		{
			"src": {
				"process": "AppView",
				"port": "event"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "State",
				"port": "state"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "state"
			}
		},
		{
			"src": {
				"process": "State",
				"port": "state"
			},
			"tgt": {
				"process": "MapStateToProps",
				"port": "state"
			}
		},
		{
			"src": {
				"process": "State",
				"port": "updated"
			},
			"tgt": {
				"process": "MapStateToProps",
				"port": "updated"
			}
		},
		{
			"src": {
				"process": "MapStateToProps",
				"port": "props"
			},
			"tgt": {
				"process": "AppView",
				"port": "updated"
			}
		},
		{
			"src": {
				"process": "StoreDispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Logger",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Logger",
				"port": "pass"
			},
			"tgt": {
				"process": "UrlMiddleware",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UrlMiddleware",
				"port": "new"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "UrlMiddleware",
				"port": "pass"
			},
			"tgt": {
				"process": "StorageMiddleware",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StorageMiddleware",
				"port": "new"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageMiddleware",
				"port": "pass"
			},
			"tgt": {
				"process": "UserMiddleware",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UserMiddleware",
				"port": "new"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "UserMiddleware",
				"port": "pass"
			},
			"tgt": {
				"process": "RegistryMiddleware",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RegistryMiddleware",
				"port": "new"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RegistryMiddleware",
				"port": "pass"
			},
			"tgt": {
				"process": "GithubMiddleware",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubMiddleware",
				"port": "new"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "GithubMiddleware",
				"port": "pass"
			},
			"tgt": {
				"process": "RuntimeMiddleware",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RuntimeMiddleware",
				"port": "new"
			},
			"tgt": {
				"process": "StoreDispatch",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeMiddleware",
				"port": "pass"
			},
			"tgt": {
				"process": "Dispatch",
				"port": "in"
			}
		},
		{
			"data": "user:*,main:*,storage:*,storage:*:*,github:*,flowhub:*,runtime:*,context:*",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "UserReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "MainContext",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "StorageReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "StorageReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "GithubReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 5
			},
			"tgt": {
				"process": "RegistryReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 6
			},
			"tgt": {
				"process": "RuntimeReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 7
			},
			"tgt": {
				"process": "ContextReducer",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "ShowErrors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "UserReducer",
				"port": "context"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GithubReducer",
				"port": "context"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RegistryReducer",
				"port": "context"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RuntimeReducer",
				"port": "context"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "StorageReducer",
				"port": "out"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "MainContext",
				"port": "out"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ContextReducer",
				"port": "context"
			},
			"tgt": {
				"process": "State",
				"port": "in"
			}
		},
		{
			"data": "noflo-ui",
			"tgt": {
				"process": "AppView",
				"port": "selector"
			}
		},
		{
			"data": "",
			"tgt": {
				"process": "ListenWindow",
				"port": "start"
			}
		},
		{
			"src": {
				"process": "ListenWindow",
				"port": "width"
			},
			"tgt": {
				"process": "AppView",
				"port": "width"
			}
		},
		{
			"src": {
				"process": "ListenWindow",
				"port": "height"
			},
			"tgt": {
				"process": "AppView",
				"port": "height"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "code"
	},
	"caseSensitive": false
};