module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"project": {
			"process": "DeleteProject",
			"port": "key"
		},
		"graph": {
			"process": "DeleteGraph",
			"port": "key"
		},
		"component": {
			"process": "DeleteComponent",
			"port": "key"
		},
		"spec": {
			"process": "DeleteSpec",
			"port": "key"
		},
		"runtime": {
			"process": "DeleteRuntime",
			"port": "key"
		},
		"error": {
			"process": "Errors",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"GetProjectsDb": {
			"component": "ui/GetActionValues"
		},
		"DeleteProject": {
			"component": "ui/Delete"
		},
		"ProjectsStore": {
			"component": "strings/SendString"
		},
		"Errors": {
			"component": "core/Merge"
		},
		"GetGraphsDb": {
			"component": "ui/GetActionValues"
		},
		"DeleteGraph": {
			"component": "ui/Delete"
		},
		"GraphsStore": {
			"component": "strings/SendString"
		},
		"GetComponentsDb": {
			"component": "ui/GetActionValues"
		},
		"DeleteComponent": {
			"component": "ui/Delete"
		},
		"ComponentsStore": {
			"component": "strings/SendString"
		},
		"GetSpecsDb": {
			"component": "ui/GetActionValues"
		},
		"DeleteSpec": {
			"component": "ui/Delete"
		},
		"SpecsStore": {
			"component": "strings/SendString"
		},
		"GetRuntimesDb": {
			"component": "ui/GetActionValues"
		},
		"DeleteRuntime": {
			"component": "ui/Delete"
		},
		"RuntimesStore": {
			"component": "strings/SendString"
		}
	},
	"connections": [
		{
			"data": "storage:delete:project,storage:delete:graph,storage:delete:component,storage:delete:spec,storage:delete:runtime",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetProjectsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "DeleteProject",
				"port": "db"
			}
		},
		{
			"data": "projects",
			"tgt": {
				"process": "ProjectsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "GetProjectsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ProjectsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ProjectsStore",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteProject",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetProjectsDb",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteProject",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "DeleteProject",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetGraphsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "DeleteGraph",
				"port": "db"
			}
		},
		{
			"data": "graphs",
			"tgt": {
				"process": "GraphsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "GetGraphsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "out"
			},
			"tgt": {
				"process": "GraphsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GraphsStore",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteGraph",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetGraphsDb",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteGraph",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "DeleteGraph",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetComponentsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "DeleteComponent",
				"port": "db"
			}
		},
		{
			"data": "components",
			"tgt": {
				"process": "ComponentsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "GetComponentsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "out"
			},
			"tgt": {
				"process": "ComponentsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "ComponentsStore",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteComponent",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetComponentsDb",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteComponent",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "DeleteComponent",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetSpecsDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "DeleteSpec",
				"port": "db"
			}
		},
		{
			"data": "specs",
			"tgt": {
				"process": "SpecsStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "GetSpecsDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "out"
			},
			"tgt": {
				"process": "SpecsStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SpecsStore",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteSpec",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetSpecsDb",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteSpec",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "DeleteSpec",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"data": "db",
			"tgt": {
				"process": "GetRuntimesDb",
				"port": "keys"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "values",
				"index": 0
			},
			"tgt": {
				"process": "DeleteRuntime",
				"port": "db"
			}
		},
		{
			"data": "runtimes",
			"tgt": {
				"process": "RuntimesStore",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "GetRuntimesDb",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimesStore",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "RuntimesStore",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteRuntime",
				"port": "store"
			}
		},
		{
			"src": {
				"process": "GetRuntimesDb",
				"port": "out"
			},
			"tgt": {
				"process": "DeleteRuntime",
				"port": "item"
			}
		},
		{
			"src": {
				"process": "DeleteRuntime",
				"port": "error"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Errors",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "trash-o"
	},
	"caseSensitive": false
};