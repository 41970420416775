module.exports = {
	"inports": {
		"in": {
			"process": "Dispatch",
			"port": "in"
		}
	},
	"outports": {
		"pass": {
			"process": "Passed",
			"port": "out"
		},
		"new": {
			"process": "NewActions",
			"port": "out"
		}
	},
	"groups": [],
	"processes": {
		"Dispatch": {
			"component": "ui/DispatchAction"
		},
		"Passed": {
			"component": "core/Merge"
		},
		"StorageDbAction": {
			"component": "ui/SetAction"
		},
		"NewActions": {
			"component": "core/Merge"
		},
		"StorageReadyAction": {
			"component": "ui/SetAction"
		},
		"StorageInitialLoadAction": {
			"component": "ui/SetAction"
		},
		"StorageInitialDataAction": {
			"component": "ui/SetAction"
		},
		"StorageProjectAction": {
			"component": "ui/SetAction"
		},
		"StorageGraphAction": {
			"component": "ui/SetAction"
		},
		"StorageComponentAction": {
			"component": "ui/SetAction"
		},
		"StorageSpecAction": {
			"component": "ui/SetAction"
		},
		"StorageRuntimeAction": {
			"component": "ui/SetAction"
		},
		"RemovedProjectAction": {
			"component": "ui/SetAction"
		},
		"RemovedGraphAction": {
			"component": "ui/SetAction"
		},
		"RemovedComponentAction": {
			"component": "ui/SetAction"
		},
		"RemovedSpecAction": {
			"component": "ui/SetAction"
		},
		"RemovedRuntimeAction": {
			"component": "ui/SetAction"
		},
		"StorageErrorAction": {
			"component": "ui/SetAction"
		},
		"StorageOpenAction": {
			"component": "ui/SetAction"
		},
		"RuntimeLoadTestsAction": {
			"component": "ui/SetAction"
		},
		"ApplicationHashAction": {
			"component": "ui/SetAction"
		},
		"DbName": {
			"component": "strings/SendString"
		},
		"PrepareStorage": {
			"component": "ui/PrepareStorage"
		},
		"LoadData": {
			"component": "ui/LoadData"
		},
		"SplitSave": {
			"component": "core/Split"
		},
		"OpenNewProject": {
			"component": "ui/OpenNewProject"
		},
		"SaveData": {
			"component": "ui/SaveData"
		},
		"DeleteData": {
			"component": "ui/DeleteData"
		},
		"OpenProject": {
			"component": "ui/OpenProject"
		}
	},
	"connections": [
		{
			"data": "noflo:ready,storage:load:*,storage:save:*,storage:delete:*,storage:open",
			"tgt": {
				"process": "Dispatch",
				"port": "routes"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "pass"
			},
			"tgt": {
				"process": "Passed",
				"port": "in"
			}
		},
		{
			"data": "storage:db",
			"tgt": {
				"process": "StorageDbAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageDbAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:ready",
			"tgt": {
				"process": "StorageReadyAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageReadyAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:load:all",
			"tgt": {
				"process": "StorageInitialLoadAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageInitialLoadAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:stored:initial",
			"tgt": {
				"process": "StorageInitialDataAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageInitialDataAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:stored:project",
			"tgt": {
				"process": "StorageProjectAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageProjectAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:stored:graph",
			"tgt": {
				"process": "StorageGraphAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageGraphAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:stored:component",
			"tgt": {
				"process": "StorageComponentAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageComponentAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:stored:spec",
			"tgt": {
				"process": "StorageSpecAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageSpecAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:stored:runtime",
			"tgt": {
				"process": "StorageRuntimeAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageRuntimeAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:removed:project",
			"tgt": {
				"process": "RemovedProjectAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RemovedProjectAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:removed:graph",
			"tgt": {
				"process": "RemovedGraphAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RemovedGraphAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:removed:component",
			"tgt": {
				"process": "RemovedComponentAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RemovedComponentAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:removed:spec",
			"tgt": {
				"process": "RemovedSpecAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RemovedSpecAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:removed:runtime",
			"tgt": {
				"process": "RemovedRuntimeAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RemovedRuntimeAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:error",
			"tgt": {
				"process": "StorageErrorAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageErrorAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "storage:opened",
			"tgt": {
				"process": "StorageOpenAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "StorageOpenAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "runtime:loadTests",
			"tgt": {
				"process": "RuntimeLoadTestsAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "RuntimeLoadTestsAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "application:sethash",
			"tgt": {
				"process": "ApplicationHashAction",
				"port": "action"
			}
		},
		{
			"src": {
				"process": "ApplicationHashAction",
				"port": "out"
			},
			"tgt": {
				"process": "NewActions",
				"port": "in"
			}
		},
		{
			"data": "noflo-ui",
			"tgt": {
				"process": "DbName",
				"port": "string"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 0
			},
			"tgt": {
				"process": "DbName",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DbName",
				"port": "out"
			},
			"tgt": {
				"process": "PrepareStorage",
				"port": "name"
			}
		},
		{
			"src": {
				"process": "PrepareStorage",
				"port": "db"
			},
			"tgt": {
				"process": "StorageDbAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PrepareStorage",
				"port": "db"
			},
			"tgt": {
				"process": "StorageInitialLoadAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "PrepareStorage",
				"port": "error"
			},
			"tgt": {
				"process": "StorageErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 1
			},
			"tgt": {
				"process": "LoadData",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadData",
				"port": "complete"
			},
			"tgt": {
				"process": "StorageInitialDataAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadData",
				"port": "complete"
			},
			"tgt": {
				"process": "StorageReadyAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "LoadData",
				"port": "error"
			},
			"tgt": {
				"process": "StorageErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 2
			},
			"tgt": {
				"process": "SplitSave",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitSave",
				"port": "out"
			},
			"tgt": {
				"process": "OpenNewProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SplitSave",
				"port": "out"
			},
			"tgt": {
				"process": "SaveData",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveData",
				"port": "project"
			},
			"tgt": {
				"process": "OpenNewProject",
				"port": "project"
			}
		},
		{
			"src": {
				"process": "OpenNewProject",
				"port": "out"
			},
			"tgt": {
				"process": "StorageProjectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenNewProject",
				"port": "hash"
			},
			"tgt": {
				"process": "ApplicationHashAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveData",
				"port": "graph"
			},
			"tgt": {
				"process": "StorageGraphAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveData",
				"port": "component"
			},
			"tgt": {
				"process": "StorageComponentAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveData",
				"port": "spec"
			},
			"tgt": {
				"process": "StorageSpecAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveData",
				"port": "runtime"
			},
			"tgt": {
				"process": "StorageRuntimeAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "SaveData",
				"port": "error"
			},
			"tgt": {
				"process": "StorageErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 3
			},
			"tgt": {
				"process": "DeleteData",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DeleteData",
				"port": "project"
			},
			"tgt": {
				"process": "RemovedProjectAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DeleteData",
				"port": "graph"
			},
			"tgt": {
				"process": "RemovedGraphAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DeleteData",
				"port": "component"
			},
			"tgt": {
				"process": "RemovedComponentAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DeleteData",
				"port": "spec"
			},
			"tgt": {
				"process": "RemovedSpecAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DeleteData",
				"port": "runtime"
			},
			"tgt": {
				"process": "RemovedRuntimeAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "DeleteData",
				"port": "error"
			},
			"tgt": {
				"process": "StorageErrorAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "Dispatch",
				"port": "handle",
				"index": 4
			},
			"tgt": {
				"process": "OpenProject",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenProject",
				"port": "out"
			},
			"tgt": {
				"process": "StorageOpenAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenProject",
				"port": "out"
			},
			"tgt": {
				"process": "RuntimeLoadTestsAction",
				"port": "in"
			}
		},
		{
			"src": {
				"process": "OpenProject",
				"port": "error"
			},
			"tgt": {
				"process": "StorageErrorAction",
				"port": "in"
			}
		}
	],
	"properties": {
		"environment": {
			"type": "noflo-browser"
		},
		"icon": "database"
	},
	"caseSensitive": false
};